export interface Notification {
  total: number;
  historyChecks: number;
  messages: number;
}

export const createNotification = (
  total: number,
  historyChecks: number,
  messages: number,
) => {
  return {
    total: total || 0,
    historyChecks: historyChecks || 0,
    messages: messages || 0,
  };
};
