import { MouseEvent } from 'react';
import { SortOptions } from 'helpers/Links/Links.typed';
import type { FilterParams } from 'helpers/Links/Links.typed';

export function safeClick(event: MouseEvent) {
  const sure = confirm('Are you sure?');
  if (!sure) {
    event.preventDefault();
  }
}

const generateSimilarCarsLink = (
  make?: string,
  model?: string,
  year?: string,
  sort?: SortOptions,
  searchFilters?: FilterParams,
) => {
  const link = `/cars/${make}/${model}/${year}`;

  const filters = searchFilters
    ? Object.fromEntries(
        Object.entries(searchFilters).filter(
          ([_, value]) => value !== undefined,
        ),
      )
    : null;

  const queryParams = new URLSearchParams({
    ...(sort ? { sort } : {}),
    ...filters,
  });

  return `${link}${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;
};

export { generateSimilarCarsLink };
