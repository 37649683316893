enum SortOptions {
  NEWEST = 'publishdatedesc',
  OLDEST = 'publishdateasc',
  MOST_EXPENSIVE = 'pricedesc',
  LEAST_EXPENSIVE = 'priceasc',
}

enum FilterOptions {
  DEALER_CARS = 'pro',
  PRIVATE_CARS = 'private',
}

type FilterParams = {
  [key: string]: string | undefined;
};

export { SortOptions, FilterOptions };
export type { FilterParams };
