import { Base } from 'domains/Base';
import { Image } from 'domains/Image';

interface IShareItem {
  url: string;
}

export interface IShare {
  whatsapp: IShareItem;
  facebook: IShareItem;
  twitter: IShareItem;
  email: IShareItem;
}

export enum AdType {
  FOR_SALE = 'for-sale',
  WANTED = 'wanted',
  C2B = 'c2b',
}

export interface Ad extends Base {
  friendlyUrl: string | null;
  share: IShare | null;
  adType: AdType[];
  county?: string;
  countyTown?: string;
  section?: string;
  parentSection?: string;
  title?: string;
  views?: string;
  price?: string;
  photos?: Image[] | null;
  age?: string;
  keyInfo?: string[];
}
