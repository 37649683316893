import type { PaymentRequest } from '@stripe/stripe-js';

import type { Currency } from 'types';

export enum PAYMENT_METHOD_TYPE {
  NATIVE = 'NATIVE',
  STRIPE = 'STRIPE',
  SAVED_CARD_STRIPE = 'SAVED_CARD_STRIPE',
  MOBILE = 'MOBILE',
  SAVED_MOBILE = 'SAVED_MOBILE',
  PAYPAL = 'PAYPAL',
  SAVED_PAYPAL = 'SAVED_PAYPAL',
  VOUCHER = 'VOUCHER',
}

export enum CURRENCY_SYMBOL {
  GBP = '£',
  EUR = '€',
}

export type TCardType = 'VISA' | 'MASTERCARD' | 'AMEX';

export type NativePayment = 'GOOGLE' | 'APPLE';

export interface CanMakePayment {
  paymentRequest: PaymentRequest;
  type: NativePayment | null;
}

export interface PaymentProduct {
  name: string;
  description: string;
  price: string;
}

export interface PaymentMethod {
  id: string | null;
  name: string;
  url: string;
  type: PAYMENT_METHOD_TYPE;
  selected: boolean;
  surcharge: string;
  availableNetworks: string[] | null;
  icon: string | null;
  display: string;
}

export interface CardPaymentMethod {
  type: TCardType;
  displayCardNumber: string;
  lastFourDigits: string;
  paymentMethodId: string;
  icon: string | null;
  isDefault: boolean;
}

export interface OrderPayment {
  methods: PaymentMethod[];
  products: PaymentProduct[];
  displayPrice: string;
  total: number;
  currency: Currency;
  defaultCard: CardPaymentMethod[];
}

export type TPaymentIntentStatus =
  | 'canceled'
  | 'processing'
  | 'requires_action'
  | 'requires_capture'
  | 'requires_confirmation'
  | 'requires_payment_method'
  | 'succeeded';

type TClientError = 'ClientError';
type TServerError = 'ServerError';
type TUnknownError = 'UnknownError';

export interface Payment {
  clientSecret: string;
  status?: TPaymentIntentStatus;
}

export type PaymentErrorType = TClientError | TServerError | TUnknownError;

export interface PaymentError {
  message: string;
  type: PaymentErrorType;
}
